/***************************************************
  Imports
***************************************************/

import React from "react"

import PageTypeE from "../../templates/PageTypeE"

/***************************************************
  Component
***************************************************/

export default () => {
  return (
    <PageTypeE
      title="Featured Benefits"
      description="Equip your organization to work as a team and make faster, smarter decisions by connecting people, information, and processes."
      linkSubPageGroupID="D365Group"
      infoList={[
        {
          title: "Analyze, Communicate, and Collaborate",
          text: `Equip your organization to work as a team and make faster, smarter decisions by connecting people, information, and processes.<br/><br/>Easily access and analyze real-time data about every aspect of your operations, including individual transactions, customized key performance indicators, trends, and growth opportunities.<br/><br/>Give people self-service reporting and business intelligence capabilities that reduce ad-hoc requests to the IT department for reports and information.<br/><br/>Automatically generate custom reports by using Report Designer, an ad-hoc query tool and component of Microsoft SQL Server Reporting Services or export to Excel or other familiar programs for further analysis and graphical presentation.<br/><br/>Accelerate business insight and expand the value of business data even further by combining Microsoft Dynamics Business Central with SQL Server Reporting Services and SQL Server Analysis Services.<br/><br/>Unleash the power to communicate and collaborate effectively-and optimize your technology investments-through deep integration with the Microsoft Office system, including Excel, Windows SharePoint Services, and Microsoft Office PerformancePoint Server.<br/><br/>Deliver online access to real-time data, reports, and collaboration tools with Employee Portal in Microsoft Dynamics Business Central, a Web-based interface built on Windows SharePoint Services- without having to set up users and train them to use Microsoft Dynamics Business Central.`,
        },
        {
          title: "Fuel Business Agility and Growth",
          text: `Meet changing business needs without disrupting your operations or your IT budget-and make the most of your current IT investments.<br/><br/>Microsoft .NET foundation, three-tier architecture, and Web services make it easier than ever to integrate Microsoft Dynamics Business Central with existing systems, share data across other applications, and develop extended functionality.<br/><br/>Automate supply chain collaboration and document exchange with partners using Microsoft BizTalk Server and the microsoft office system.<br/><br/>Choose the solution package you need today with flexible business licensing options, and then add capabilities as your business grows-quickly and cost-effectively.<br/><br/>Take advantage of expert support from your Microsoft Certified Partner, who can help you implement your solution efficiently and cost-effectively, and help your company benefit from a rich ecosystem of finely tailored, industry-specific offerings and add-on solutions.<br/><br/>Help ensure efficient, consistent deployments with the Rapid Implementation Methodology, part of Microsoft Dynamics Sure Step, a standardized, global methodology and suite of tools that simplifies implementation and upgrade processes.`,
        },
        {
          title: "Focus on Achieving Business Goals",
          text: `Microsoft Dynamics Business Central can help you automate and simplify processes across your entire business to boost productivity, performance, and business growth. With integrated financial, supply chain, and customer relationship management information from across your organization stored in a centralized database, your people can work quickly and effectively-helping to drive your business forward.`,
        },
      ]}
    />
  )
}
